import { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {validateWCAToken} from '../../services/AWS/user/user';
import {useDispatch, useSelector} from "react-redux";
import {sessionDataActions} from "../../store";

 const wcaAccessToken = () => localStorage.getItem(localStorageKey('accessToken'));
 const getMe = () => {

  const userInfo = validateWCAToken(wcaAccessToken());

  return userInfo;
  };

  export const wcaApiFetch = async (path, fetchOptions = {}) => {
    const baseApiUrl = `${process.env.REACT_APP_WCA_URL}/api/v0`;
    const res = await fetch(
      `${baseApiUrl}${path}`,
      Object.assign({}, fetchOptions, {
        headers: new Headers({
          Authorization: `Bearer ${wcaAccessToken()}`,
          'Content-Type': 'application/json',
        }),
      })
    );
  
    if (!res.ok) {
      throw new Error(`${res.status}${res.statusText ? `: ${res.statusText}` : ''}`);
    }
  
    return await res.json();
  };


  const localStorageKey = (key) => `groups.${process.env.REACT_APP_WCA_OAUTH_CLIENT_ID}.${key}`;
  const getLocalStorage = (key) => localStorage.getItem(localStorageKey(key));
  const setLocalStorage = (key, value) => localStorage.setItem(localStorageKey(key), value);

const oauthRedirectUri = () => {
  const appUri = window.location.origin;
  const searchParams = new URLSearchParams(window.location.search);
  const stagingParam = searchParams.has('staging');
  return stagingParam ? `${appUri}?staging=true` : appUri;
};

const AuthContext = createContext(null);

export default function AuthProvider({ children }) {
  const [accessToken, setAccessToken] = useState(getLocalStorage('accessToken'));
  const [user, setUser] = useState(null);
  const [justLoggedIn, setjustLoggedIn] = useState(false);
  const [previousPage, setpreviousPage] = useState(undefined);
  const [userFetchError, setUserFetchError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
      executeAuth();

  }, [location, navigate]);
const executeAuth = async() => {
    dispatch(sessionDataActions.WCAAuthLoading());
    const hash = window.location.hash.replace(/^#/, '');
    const hashParams = new URLSearchParams(hash);
    if (hashParams.has('access_token') && accessToken !== hashParams.get('access_token')) {
        setjustLoggedIn(true);
        // set access token
        setAccessToken(hashParams.get('access_token'));
        setLocalStorage('accessToken', hashParams.get('access_token'));
      await  getMe()
            .then(( userInfo) => {
                setLocalStorage('MYCAaccessToken',userInfo.MYCAToken);
                const userString = JSON.stringify(userInfo.me);
                setLocalStorage('userInformation',userString);
                dispatch(sessionDataActions.SetUserInformation(userInfo.me));
                dispatch(sessionDataActions.WCALoggedIn())
            })
            .catch((err) => {
                console.error("eeror in logging in");
                setUserFetchError(err);
                signOut();
            });
    }
    if (hashParams.has('expires_in')) {
        /* Expire the token 15 minutes before it actually does,
           this way it doesn't expire right after the user enters the page. */
        const expiresInSeconds = hashParams.get('expires_in') - 15 * 60;
        const expirationTime = new Date(new Date().getTime() + expiresInSeconds * 1000);
        setLocalStorage('expirationTime', expirationTime.toISOString());
    }

    /* If the token expired, sign the user out. */
    const expirationTime = getLocalStorage('expirationTime');
    if (expirationTime && new Date() >= new Date(expirationTime)) {
        localStorage.removeItem(localStorageKey('expirationTime'));
        signOut();
    }
    const token = getLocalStorage('accessToken');
    const userInformation = getLocalStorage('userInformation');

    if (token ) {
        const userInformationJson = JSON.parse(userInformation);
        setAccessToken(token);
        dispatch(sessionDataActions.SetUserInformation(userInformationJson));
        dispatch(sessionDataActions.WCALoggedIn())
    }
    /* Clear the hash if there is a token. */
    if (hashParams.has('access_token')) {
        setjustLoggedIn(true);
        if (getLocalStorage("previousPage") !== undefined && getLocalStorage("accessToken") != undefined){
            const previousPage = getLocalStorage("previousPage");
            localStorage.removeItem(localStorageKey('previousPage'));
            navigate(previousPage);
        }
        else{
            //get current page url
            navigate({
                to: {
                    replace: '/',
                    hash: null,
                    state: location.state,
                },
            });
        }

    }
    dispatch(sessionDataActions.WCAAuthLoaded());

}
  const signedIn = useCallback(() => !!accessToken, [accessToken]);

  const signIn = () => {
    const params = new URLSearchParams({
      client_id: process.env.REACT_APP_WCA_OAUTH_CLIENT_ID,
      response_type: 'token',
      redirect_uri: oauthRedirectUri(),
      scope: 'public email dob',
    });
    //get current page url
    setLocalStorage("previousPage",location.pathname);
    setpreviousPage(location.pathname);
    window.location = `${process.env.REACT_APP_WCA_URL}/oauth/authorize?${params.toString()}`;
  };

  const signOut = () => {
    setAccessToken(null);
    localStorage.removeItem(localStorageKey('accessToken'));
    localStorage.removeItem(localStorageKey('MYCAaccessToken'));
    localStorage.removeItem(localStorageKey('userInformation'));
  //  console.log("singed out")
    dispatch(sessionDataActions.SetUserInformation(null));
    dispatch(sessionDataActions.WCALoggedOut())
    //refresh page
    window.location.reload();
  };

  const value = { user,justLoggedIn,setjustLoggedIn,previousPage, signIn, signOut, signedIn, userFetchError };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);
