import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {ReactDOM} from "react-dom";
import "./EmailVerification.css";
import {verifyUserEmailService} from "../../services/AWS/user/user";
import {Amplify,Auth} from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import {sessionDataActions} from "../../store";
const VerifyEmailPage = () => {
  const {id} = useParams();
const [inputs,setInputs] = useState();
const [errorMessages,setErrorMessages] = useState({});
const [SubmitSuccess,setSubmitSuccess] = useState();
  const dispatch = useDispatch();
const REACT_APP_AWSREGION = process.env.REACT_APP_AWSREGION;
  Amplify.configure({
    Auth: {
      userPoolId: process.env.REACT_APP_USERPOOL_ID, //UserPool ID
      region: REACT_APP_AWSREGION,
      userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEBCLIENT //WebClientId
    }
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }
  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    //check if email is blank and regex
    if (inputs.email === undefined || inputs.email === "")
    {
        setErrorMessages({name:"email",message:"Email cannot be blank!"})
        return;
    }
    if (!inputs.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
    {
        setErrorMessages({name:"email",message:"Invalid Email!"})
        return;
    }
    console.log(inputs.email)
    const redirectUrl = `/VerifyEmail/${inputs.email}`;
    console.log(redirectUrl)
    //redirect to path having email at params
    window.location.href = redirectUrl;

  }

  const handleSubmit = async (event) => {
    //Prevent page reload
    event.preventDefault();

    //validate input
    if (inputs.verificationCode.length != 6)
    {
      setErrorMessages({name:"verificationCode",message:"Wrong Verification Code!"})
      console.log("invalid Password");
      return;
    }
    try {
      dispatch(sessionDataActions.loading());;
      await Auth.confirmSignUp(id, inputs.verificationCode);
      dispatch(sessionDataActions.loaded());;
    } catch (error) {
      dispatch(sessionDataActions.loaded());;
      setErrorMessages({name:"verificationCode",error})
    }
    setSubmitSuccess(true);

  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
      <div className="input-container">
          <label>Your Email Address is: {id} </label>
        </div>
        <div className="input-container">
          <label>Please enter the verification code send to your email if you just done your account registration:* </label>
          <input type="text" name="verificationCode" required onChange={handleChange}/>
          {renderErrorMessage("verificationCode")}
          
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  const FillEmailForm = (
    <div className="form">
        <form onSubmit={handleEmailSubmit}>
        <div className="input-container">
            <label>Please enter your email address if you just done your account registration:* </label>
            <input type="text" name="email" required onChange={handleChange}/>
            {renderErrorMessage("email")}
          </div>
          <div className="button-container">
            <input type="submit" />
          </div>
          </form>
            </div>)

  return (
    <div className="app">
      <div className="register-form">
        <div className="title">Account Registration Email Verification</div>
        {id ? SubmitSuccess ? <div><p1>Account Verified successfully!</p1> <a style={{color:"#2E8BC0"}} href="/home" rel="noreferrer"> Go to Home Page
        </a></div> :renderForm : FillEmailForm}

        
      </div>
    </div>
  );
  }

export default VerifyEmailPage;