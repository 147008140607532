import React, { useState,useEffect } from "react";
import ReactDOM from "react-dom";
import "./Login.css";
import {Amplify,Auth} from 'aws-amplify';
import { useDispatch,useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import {createBreakpoint} from "react-use";
import {sessionDataActions} from "../../store";
function LoginPage() {
    const dispatch = useDispatch();
  // React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const REACT_APP_AWSREGION = process.env.REACT_APP_AWSREGION;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const redirect_uri = params.get('redirect_uri');
    Amplify.configure({
        Auth: {
            userPoolId: process.env.REACT_APP_USERPOOL_ID, //UserPool ID
            region: REACT_APP_AWSREGION,
            userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEBCLIENT //WebClientId
        }
    });

  const GetloginStatus =async ()=>{
    try {
      const AuthInfo = await Auth.currentAuthenticatedUser();
     if (AuthInfo.username === undefined) {
      setIsSubmitted(false);
     }
     else{
      setIsSubmitted(true);
     }
  } catch {
    setIsSubmitted(false);

  }
  }
  useEffect(() => {
    GetloginStatus();
  },[isSubmitted]);

  const errors = {
    uname: "invalid username",
    pass: "invalid password",
    wrongAccount:"Invalid Account Information !"
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const handleSubmit =async (event) => {
    //Prevent page reload

    event.preventDefault();
      dispatch(sessionDataActions.loading());
    var { uname, pass } = document.forms[0];
  await  Auth.signIn(uname.value, pass.value).then((result) => {
      setIsSubmitted(true);
      if (redirect_uri){
          //redirect to the page that user want to go
          window.location = redirect_uri;
      }
      else{
          refreshPage() ;
      }

     }).catch((err) => {
        dispatch(sessionDataActions.loaded());
      setErrorMessages({name:"wrongAccount",message:"Invalid Account Information !"})

     })
      dispatch(sessionDataActions.loaded());
  
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  const renderForm = (
    <div className="form">
 
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Email </label>
          <input type="text" name="uname" required />
          {renderErrorMessage("uname")}
        </div>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pass" required />
          {renderErrorMessage("pass")}
          {renderErrorMessage("wrongAccount")}
        </div>
        <div className="button-container">
          <input style={{borderRadius:"10px",fontFamily:"Arial",fontWeight:"Bold",fontSize:"15"}} type="submit" />
        </div>
      </form>
    </div>
  );
  return (
      <div className="login-form">
        <h2 className="title">MYCSA Member Sign In</h2>
          {isSubmitted ? <div>You have logged in successfully <br/><a style={{color:"#2E8BC0"}} href="/home" rel="noreferrer"> Go to Home Page
          </a></div> : <div>{renderForm}<div style={{display:"flex",padding:"10px"}}>No Account?
     <a style={{color:"#2E8BC0"}} href="/register" rel="noreferrer"> Register Here</a>
          </div>
              <div style={{display:"flex",padding:"10px"}}>  <a style={{color:"#2E8BC0"}} href="/forgotPassword" rel="noreferrer"> Forget Password</a></div>
              <div style={{display:"flex",padding:"10px"}}>  <a style={{color:"#2E8BC0"}} href="/VerifyEmail" rel="noreferrer"> Verify Email</a></div>
          </div>

          }

      </div>
  );
}

const ResponsiveLogin = () => {
    const useBreakpoint = createBreakpoint();
    const breakpoint = useBreakpoint();
    const isMobile = breakpoint === "tablet";
    return (
        <div>
            {isMobile?
                <div style={{ maxWidth: "400px", minWidth: "300px"}}><LoginPage /></div>  :
                <div style={{"margin-left":"40vw",maxWidth:"400px"}}><LoginPage /> </div>
}
        </div>
  );
}



export default ResponsiveLogin;