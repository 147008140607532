const filterObjectByKeyPartialvalue = (obj, value, key) => {
    // Split keys and trim any whitespace
    const keys = key.split(",").map(k => k.trim());

    // Remove all spaces from the search value
    const cleanSearchValue = value.replace(/\s/g, '').toUpperCase();

    // Use filter to create a new array of matching objects
    return obj.filter(item =>
        keys.some(k => {
            // Handle undefined or null values
            const itemValue = item[k] === undefined ? "" : item[k];

            // Remove all spaces and convert to uppercase for comparison
            const cleanItemValue = String(itemValue)
                .replace(/\s/g, '')
                .toUpperCase();

            // Check if the cleaned item value includes the cleaned search value
            return cleanItemValue.includes(cleanSearchValue);
        })
    );
};

const filterObjectByKeyvalue = (obj, value,key) => {
    // loop every object in the array
    let returnArry = [];
    for (let i = 0; i < obj.length; i++) {
        // if the value of the key is equal to the value we are looking for
        if (obj[i][key] === value) {
            // add the row into the new array
            returnArry.push(obj[i]);
        }
    }
    // if the value is not found, return null
    return returnArry;
};

export { filterObjectByKeyPartialvalue,filterObjectByKeyvalue };