import {useDispatch, useSelector} from "react-redux";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from "@mui/material/Typography";
import * as React from "react";
import Box from '@mui/material/Box';
import MYCA_LOGO from "../portal/image/MYCA_LOGO.jpg";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import {useEffect, useState} from "react";
import {getUserinfo, makeMemberPaymentCurlec} from "../../../services/AWS/user/user";
import moment from "moment";
import Button from "@mui/material/Button";
import{makeMemberPayment} from "../../../services/AWS/user/user";
import {sessionDataActions} from "../../../store";
import {PaymentPendingPage} from "../../../Component/PaymentPending/PaymentPendingPage";


export const PurchaseMemberPage = (props) => {
    const dispatch = useDispatch();
    const [displayPendingPayment, setDisplayPendingPayment] = useState(false);
    const authToken = useSelector(state => state.sessionData.authToken);
    const isLoggedIn = useSelector(state => state.sessionData.isLoggedIn);
    const [userInfo,setUserInfo] = useState(null);
    const setUserInformation  = async (authToken) => {
        dispatch(sessionDataActions.loading());
        const userInfo =await  getUserinfo(authToken)
        setUserInfo(userInfo);
        dispatch(sessionDataActions.loaded());
    }
    useEffect(() => {
            if (isLoggedIn && authToken){
                setUserInformation(authToken);
            }
        },[authToken]
    )

    const handleStripePaymentSubmit = async (event) => {
        event.preventDefault();
        setDisplayPendingPayment(true);
        if (userInfo  == null){
            return;
        }
        const data = await makeMemberPaymentCurlec(userInfo.userID,1,authToken);

        const paymentInfo = data.respond.paymentData;
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razropay failed to load!!')
            dispatch( sessionDataActions.loaded());
            return
        }
        const options = {
            "key": process.env.REACT_APP_CURLEC_KEY_ID, // Enter the Key ID generated from the Dashboard
            "amount": paymentInfo.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": paymentInfo.currency,
            "name": "Malaysia Cube Sport Association",
            "description": "Premium Membership Subscription",
            "order_id": paymentInfo.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "callback_url":`${process.env.AWSPUBLICAPI}CurlecPaymentRedirect`,
            "theme": {
                "color": "#3399cc"
            },
            "timeout":600,
        };

        var rzp1 = new Razorpay(options);
        rzp1.open();



        return;

    }
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }
return (
    <Box>
        {displayPendingPayment && <PaymentPendingPage/>}
        {displayPendingPayment === false && <Card variant="elevation"
               sx={{minWidth: "360px", maxWidth: "360px", marginLeft: "auto", marginRight: "auto", marginTop: "10px"}}>

            <CardMedia
                sx={{height: 125, border: "2px #00000099 solid"}}
                image={MYCA_LOGO}
                title="Membership_Background"
            />
            <CardContent>

                <Typography variant="h6" component="div" sx={{}}>
                    Your Account Information
                </Typography>
                <table style={{width: "100%"}}>
                    <tr>
                        <td className="InfoHeader"><b>Full Name</b></td>
                        <td className="InfoContent">{userInfo && userInfo.Name} </td>
                    </tr>
                    <tr>
                        <td className="InfoHeader"><b>email</b></td>
                        <td className="InfoContent">{userInfo && userInfo.email} </td>
                    </tr>
                    <tr>
                        <td className="InfoHeader"><b>Current Premium Member Due Date</b></td>
                        <td className="InfoContent">{userInfo && userInfo.hasOwnProperty("PremiumMemberDueDate") ? moment.unix(userInfo.PremiumMemberDueDate).format("DD-MM-YYYY") : "-"} </td>
                    </tr>
                    <tr>
                        <td className="InfoHeader"><b>New Expiry date after renewal</b></td>
                        <td className="InfoContent">{userInfo && userInfo.hasOwnProperty("PremiumMemberDueDate") && moment.unix(userInfo.PremiumMemberDueDate) > moment() ? moment.unix(userInfo.PremiumMemberDueDate).add("1", "year").format("DD-MM-YYYY") : moment().add("1", "year").format("DD-MM-YYYY")} </td>
                    </tr>
                </table>
                <Typography variant="h6" component="div" sx={{fontWeight: 'bold'}}>
                    Premium Membership Subscription
                </Typography>
                <table style={{width: "100%", marginBottom: "5%"}}>
                    <tr>
                        <td className="InfoHeader"><b>Annual Fee</b></td>
                        <td className="InfoContent">RM 60</td>
                    </tr>
                    <tr>
                        <td className="InfoHeader"><b>First Time registration Fee</b></td>
                        <td className="InfoContent">{(userInfo && userInfo.hasOwnProperty("PremiumMemberDueDate") && userInfo.PremiumMemberDueDate !== null ? "RM 0" : "RM20")} </td>
                    </tr>
                    <tr>
                        <td className="InfoHeader"><b>Purchase Option</b></td>
                        <td className="InfoContent">One year Renewal</td>
                    </tr>
                </table>
                <Typography variant="h6" component="div" sx={{fontWeight: 'bold', fontSize: 20, marginTop: "10px"}}>
                    {(userInfo && userInfo.hasOwnProperty("PremiumMemberDueDate") && userInfo.PremiumMemberDueDate !== null ? "Amount to Pay: RM 60" : "Amount to Pay: RM80")}
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={handleStripePaymentSubmit} variant="contained" size="large" sx={{fontWeight: "bold"}}>Make
                    Payment</Button>
            </CardActions>
        </Card>}
    </Box>
)
}
