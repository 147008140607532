  import axios from 'axios';


const verifyUserEmailService = async (userVerifyInfo) =>{
  const baseURL = process.env.REACT_APP_AWSUSERAPI + "confirmregister";
  let APIReturn;
  await axios.post(baseURL,{requestData:{
    username:userVerifyInfo.username,
    code:userVerifyInfo.code
  }}).then((response) =>{
    APIReturn = response;

  });
  return APIReturn;


}
const validateWCAToken = async (WCAToken) =>{
  const baseURL = process.env.REACT_APP_AWSPUBLICAPI + "VaildateWCAToken";
  let APIReturn;
  try{
    await axios.post(baseURL,{requestData:{
      WCAToken:WCAToken
    }}).then((response) =>{
      APIReturn = response.data;
    });
    return APIReturn;
  }
  catch(error){
    return {Auth:false}
  }



}

const  userRegisterService = async (userRegisterInfo) =>{
const baseURL = process.env.REACT_APP_AWSUSERAPI + "register";
let APIReturn;
await axios
.post(baseURL, {requestData:{    username: userRegisterInfo.username,
  password: userRegisterInfo.password,
  name: userRegisterInfo.name,
  phone: userRegisterInfo.phone,
  WCAID: userRegisterInfo.wcaID, 
  country:userRegisterInfo.country,
  birthdate:userRegisterInfo.birthdate,
  gender:userRegisterInfo.gender,
    state:userRegisterInfo.state,
    identityNo:userRegisterInfo.identityNo
}

}).then((response) => {
  APIReturn = response;
});

return APIReturn.data;
}
const getUserinfo = async (authToken) => {
  const baseURL = process.env.REACT_APP_AWSUSERAPI + "getuserinfo";
  let APIReturn;
  const options = {
    headers: {
        "Authorization": authToken
    }

  }
  await axios.post(baseURL,{},options).then((response) =>{
    APIReturn = response.data;
  }
    );
    return APIReturn;
}

export const makeMemberPayment = async (memberID,renewTerm,authToken) => {
  const baseURL = process.env.REACT_APP_AWSUSERAPI + "makeMemberPayment";
  let APIReturn;
  const options = {
    headers: {
      "Authorization": authToken
    }
  }
    await axios.post(baseURL,{requestData:{memberID,renewTerm}},options).then((response) =>{
      APIReturn = response.data;
    });
  return APIReturn;
}
  export const makeMemberPaymentCurlec = async (memberID,renewTerm,authToken) => {
    const baseURL = process.env.REACT_APP_AWSUSERAPI + "makeMemberPaymentCurlec";
    let APIReturn;
    const options = {
      headers: {
        "Authorization": authToken
      }
    }
    await axios.post(baseURL,{requestData:{memberID,renewTerm}},options).then((response) =>{
      APIReturn = response.data;
    });
    return APIReturn;
  }

  //get api to https://api.country.is to get country code
  const getCountryCode = async () => {
  try {

    const baseURL = "https://api.country.is";
    let APIReturn;
    await axios.get(baseURL,{timeout: 500}).then((response) => {
      APIReturn = response.data;
    });
    console.log(APIReturn)
    if (APIReturn && APIReturn.country == "CN") {
      return {isChina: true}
    } else {
      return {isChina: false};
    }
  }
     catch
       (error)
       {
         console.log(error)
         return {isChina: false};
       }
     }





export {userRegisterService,verifyUserEmailService,validateWCAToken,getUserinfo,getCountryCode};