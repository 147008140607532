import './ParticipantListPage.css';
import { useParams } from "react-router-dom";
import React, { useState,useEffect } from "react";
import {getParticipantList} from "../../../services/AWS/competitionInformation/CompetitionService";
import LoadingOverlay from "../../loadingPage/LoadingPage";
import {CompetitionMenuItem, EventIconComponent} from '../../../Component/Competition/CompetitionComponent';
import moment from "moment";
import {getPreCompRanking} from "../../../services/WCA/wcaService";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {DataGrid} from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import {filterObjectByKeyvalue,filterObjectByKeyPartialvalue} from "../../../utils/filter";
import TextField from "@mui/material/TextField";

const ParticipantListPage = (props) =>{
    // eslint-disable-next-line no-restricted-globals
    const isAC2024Path = location.pathname === '/RubiksWCAAsianChampionship2024/Home'||location.pathname === '/competition/ParticipantList/RubiksWCAAsianChampionship2024';
    const {id} = useParams();
    const [isLoading,setIsLoading] = useState(false);
  const [List, setItems] = useState([]);
  const [CompetitionName, setCompetitionName] = useState("");
  const [CompDetails, setCompDetails] = useState({});
    const [FilterState, setFilterState] = useState('Approved');
    const [filteredRows,setFilteredRow] = useState([]);
    const [SelectedEvent, setSelectedEvent] = useState("-");
    const [eventList, setEventList] = useState([]);
    const [disableSort, setDisableSort] = useState(false);
    const [filterText, setFilterText] = useState('');

  props.setCompetitionID(id);
  useEffect(() => {
    getPartList(id);
  },[]);



    const rows = List;


    useEffect(() => {
        if (SelectedEvent !== "-" ){
            return;
        }
        let filterrows = [];
        if (FilterState == 'Approved'){
            filterrows = filterObjectByKeyvalue(rows,true,'approved');
        }
        else if (FilterState == 'Pending_Approval'){
            setSelectedEvent("-")
            filterrows = filterObjectByKeyvalue(rows,true,'waitingList');
        }

        if (filterText.trim() != '') {
            filterrows = filterObjectByKeyPartialvalue(filterrows, filterText, 'UserName,WCAID');
        }



        setFilteredRow(filterrows);
        setColumns(fullListColumns);
    },[FilterState,filterText,rows,List]);

    const fullListColumns = [
        {
            field: 'UserName',
            headerName: 'Name',
            width: 200,
            editable: false,
        },
        {
            field: 'WCAID',
            headerName: 'WCA ID',
            width: 100,
            editable: false,
        },
        {
            field: 'country',
            headerName: 'Representing',
            width: 150,
            editable: false,
        },
        {
            field: 'timeSlot',
            headerName: 'Time Slot',
            width: 200,
            editable: false,
        },
        {
            field: 'events',
            headerName: 'Events',
            renderCell: (params) => (
                <div className="events">
                    <EventIconComponent events={params.value} />
                </div>
            ),
            width: 300,
            editable: false,
        },
        {
            field: 'PaidTime',
            headerName: 'Registration Completion Time (UTC+8)',
            width: 200,
            editable: false,
        }

    ];
    const PreRankingColumns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 300,
            editable: false,
        },
        {
            field: 'wcaId',
            headerName: 'WCA ID',
            width: 130,
            editable: false,
        },
        {
            field: 'average',
            headerName: 'Average',
            width: 150,
            editable: false,
        },
        {
            field: 'single',
            headerName: 'Single',
            width: 150,
            editable: false,
        },
    ];
    const [columns, setColumns] = useState([]);
    const handleBoxChange = (event) => {
        setSelectedEvent("-");
        setDisableSort(false );
        setFilterState(event.target.value);
    };

    const handleFilterNameChange = (event) => {

        setFilterText(event.target.value);

    }

    const handlePreCompEventChange =async (event) => {
        setDisableSort(true);
        setFilterState("Approved");
        setSelectedEvent(event.target.value);
        if (event.target.value == "-") {
            // reset back the filter
            setColumns(fullListColumns);
            setFilteredRow(filterObjectByKeyvalue(rows,true,'approved'));
            return;
        }
        //remove spacing
        let competitionName = "";
        if (CompDetails.hasOwnProperty("wcaURL")){
            competitionName = CompDetails.wcaURL.split("/").pop();
        }
        else {
            competitionName = CompDetails.Competition_name.replace(/\s/g, '');
        }


        const data = await getPreCompRanking(competitionName, event.target.value);
        setColumns(PreRankingColumns);
        setFilteredRow(data);
    }
  const  getPartList =async (id) =>{
    setIsLoading(true);
    const respondData = await getParticipantList(id);
    const eventList2 = respondData.list;
    await Promise.all(eventList2.map(async (item) => {

            if (item.timeSlot === "AM"){
                item.timeSlot = "Morning";
            }
            else if (item.timeSlot === "PM"){
                item.timeSlot = "Afternoon (Group B)";
            }
            else {
                item.timeSlot = "";
            }
        item.PaidTime = item.PaidTime ? moment.unix(item.PaidTime).format("DD-MM-YYYY HH:MM:ss") :  moment(item.Register_date).format("DD-MM-YYYY HH:MM:ss");
    }));
    //sort eventList2 by Register_date
    eventList2.sort((a, b) => {
        return new Date(a.PaidTime) - new Date(b.PaidTime);
    });
    setCompetitionName(respondData.CompDetails.Competition_name);
    setItems(eventList2);
    setCompDetails(respondData.CompDetails);
      if (respondData.CompDetails.hasOwnProperty("Events")){
          setEventList(respondData.CompDetails.Events.split(","));
      }
    setIsLoading(false);
 }

    return (
        <div style={{display:"flex",marginLeft:"2%"}}>
            <div>
                <h1 style={{marginLeft: 10}}>{CompetitionName}</h1>
                <h2 style={{marginLeft: 10}}>Participant List</h2>
                <CompetitionMenuItem competition={id} isAC2024Path={isAC2024Path}/>
                <br/>
                <Box sx={{ width: "100%",minHeight:"80vh" , minWidth: 0 }}>

                        <Box sx={{ minWidth: 100, maxWidth: 200}}>
                            <TextField id="standard-basic" label="Name, WCA ID" variant="standard" onChange={handleFilterNameChange}/>
                        </Box>
                    <br/>
                    <FormControl fullWidth>
                        <InputLabel  >Registration Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={FilterState}
                            label="Registration Status"
                            onChange={handleBoxChange}
                            style={{width: 200,borderRadius:"30px",marginBottom:"10px"}}
                        >
                            <MenuItem value={"Approved"}>Approved</MenuItem>
                            <MenuItem value={"Pending_Approval"}>Waiting List</MenuItem>
                        </Select>

                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel  id="event-select-label">Event Pre-Ranking</InputLabel>
                        <Select
                            labelId="pre-Comp-select-label"
                            id="pre-Comp-select"
                            value={SelectedEvent}
                            label="Pre-Comp Ranking"
                            onChange={handlePreCompEventChange}
                            style={{width: 200,borderRadius:"30px",marginBottom:"10px"}}
                        >
                            <MenuItem value={"-"}>None</MenuItem>
                            {eventList.map((event) => {
                                return <MenuItem value={event}>{event}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <h4>You will need to make full payment to be in the waiting list. The priority is order by the date of registration.</h4>

                    <DataGrid
                        sx={{borderRadius:"20px",marginBottom:"20px",width:"90vw",minHeight:"30px"}}
                        rows={filteredRows}
                        columns={columns}
                        autoHeight={true}
                        getRowId={(row) => row.ID}
                        pageSize={100}
                        pageSizeOptions={[100]}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    No participant
                                </Stack>
                            ),
                            NoResultsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="left">
                                    No participant
                                </Stack>
                            )
                        }}
                        disableRowSelectionOnClick
                    />
                </Box>
                <br/>
            </div>
            <LoadingOverlay isLoading={isLoading}/>
        </div>


    )
}
export default ParticipantListPage;