import {Amplify, Auth} from 'aws-amplify';
import React, {useState} from "react";

export const ForgetPasswordPage = () => {
    const REACT_APP_AWSREGION = process.env.REACT_APP_AWSREGION;
    const [inputs,setInputs] = useState();
    const [errorMessages,setErrorMessages] = useState({});
    const [EmailSent,setEmailSent] = useState(false);
    const [SubmitSuccess,setSubmitSuccess] = useState(false);
    Amplify.configure({
        Auth: {
            userPoolId: process.env.REACT_APP_USERPOOL_ID, //UserPool ID
            region: REACT_APP_AWSREGION,
            userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEBCLIENT //WebClientId
        }
    });
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
       setInputs(values => ({...values, [name]: value}))
    }
    // Send confirmation code to user's email
    async function SendforgotPasswordCode(emailAddress) {
        try {
            console.log("submit")
            const data = await Auth.forgotPassword(emailAddress);
            console.log(data);
            setEmailSent(true);
        } catch(err) {
            setErrorMessages({name:"confirmPassword",message:err.message})
            console.log(err);
        }
    }

// Collect confirmation code and new password
    async function forgotPasswordSubmit(username, code, newPassword) {
        try {
            const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
            console.log(data);
            setSubmitSuccess(true);
        } catch(err) {
            setErrorMessages({name:"confirmPassword",message:err.message})
            console.log(err);
        }
    }
   const handleForgotPasswordSubmit = async (event) => {
        event.preventDefault();
        if (!inputs.verificationCode){
            setErrorMessages({name:"confirmPassword",message:"Please enter your Verification Code!"})
            return;
        }
       if (!inputs.password){
           setErrorMessages({name:"confirmPassword",message:"Please enter Password!"})
           return;
       }
       if (!inputs.confirmPassword){
           setErrorMessages({name:"confirmPassword",message:"Confirm Password is not the same!"})
           return;
       }
    await   forgotPasswordSubmit(inputs.emailAddress,inputs.verificationCode,inputs.password);
        setEmailSent(true);

    }
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    const handleEmailSubmit = async (event) => {
        event.preventDefault();
        if (!inputs.emailAddress) {
            console.log("email error")
            setErrorMessages({name: "verificationCode", message: "Please enter your MYCSA Email address!"})
            return;
        }
        //check email regex
        const emailRegex = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");
        if (!emailRegex.test(inputs.emailAddress)) {
            setErrorMessages({name: "verificationCode", message: "Please enter a valid email address!"})
            return;
        }
        SendforgotPasswordCode(inputs.emailAddress)
    }
        return (
            <div className="app">
                {SubmitSuccess ? <SucessMessage/> :
                 !EmailSent ?
                    <div className="register-form">
                        <form onSubmit={handleEmailSubmit}>
                            <h1><b>Forgot Password</b></h1>
                            <div key="emailAddressForm" className="input-container">
                                <label>Please enter your MYCSA Email address:* </label>
                                <input key="emailAddress" type="text" name="emailAddress" required
                                       onChange={handleChange}/>
                                {renderErrorMessage("verificationCode")}

                            </div>
                            <div className="button-container">
                                <input type="submit"/>
                            </div>
                        </form>
                    </div>
                    : <div className="register-form">
                        <form onSubmit={handleForgotPasswordSubmit}>
                            <div className="input-container">
                                <label> </label>
                            </div>
                            <div className="input-container">
                                <label>Please enter your Verfication Code that send to your email:* </label>
                                <input type="text" name="verificationCode" required onChange={handleChange}/>

                            </div>
                            <div className="input-container">
                                <label>Please enter your new Password:* </label>
                                <input type="password" name="password" required onChange={handleChange}/>

                            </div>
                            <div className="input-container">
                                <label>Confirm New Password* </label>
                                <input type="password" name="confirmPassword" required onChange={handleChange}/>
                            </div>
                            {renderErrorMessage("confirmPassword")}
                            <div className="button-container">
                                <input type="submit"/>
                            </div>
                        </form>
                    </div>}
            </div>
        )
        const SucessMessage = () => {
            return (
                <div>
                    <h1>Success!</h1>
                    <p>Your password has been reset. You may close this window now.</p>
                    <a style={{color: "#2E8BC0"}} href="/home" rel="noreferrer"> Go to Home Page
                    </a>
                </div>
            )
        }

}
