import React, { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import './CompetitionInfo.css';
import 'react-pro-sidebar/dist/css/styles.css';
import {getEventDetails} from "../../../services/AWS/competitionInformation/CompetitionService";
import LoadingOverlay from "../../loadingPage/LoadingPage";
import Button from '@mui/material/Button';
import {createBreakpoint} from "react-use";
import Alert from "@mui/material/Alert";
import {CompetitionMenuItem, EventIconComponent} from "../../../Component/Competition/CompetitionComponent";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
const moment = require('moment');


const ListRowComponent = (props) => {
    const event = props.event? props.event.split(":") : [];
    const eventName = event[0];
    const eventPrice = event[1];
    return (
        <ListItem>
            <ListItemText sx={{height:"20px"}}  primary={eventName} secondary={`RM ${eventPrice}`} />
        </ListItem>
    )

}
const EventPriceList = (props) => {
    const eventArray = props.events? props.events.split(",") : [];
    return (
        <List sx={{ marginTop:"0px", width: '100%', maxWidth: 200, bgcolor: '#e0f2f1' ,borderRadius:"0.5rem" }}>
            {eventArray.map((event) => (<ListRowComponent event={event}/>)
            )}
        </List>
    )

}
const CompInfoPage = (props) =>{

const {id} = useParams();
const [compDetails,setcompDetails] = useState([]);
const [isLoading,setIsLoading] = useState(false);
  const useBreakpoint = createBreakpoint();
  const breakpoint = useBreakpoint();
    // eslint-disable-next-line no-restricted-globals
    const isAC2024Path = location.pathname === '/RubiksWCAAsianChampionship2024/Home'||location.pathname === '/competition/info/RubiksWCAAsianChampionship2024';
  const isMobile = breakpoint === "tablet";
props.setCompetitionID(id);
useEffect(() =>{

  GetCompetitionDetails(id);
},[]);

const GetCompetitionDetails = async (comp_id) =>{
  setIsLoading(true);
  const getcompdetails =await  getEventDetails(comp_id);
  //transform base charge
    if (getcompdetails.Base_charges !== null && getcompdetails.Base_charges !== undefined){
        const baseChargeArray = getcompdetails.Base_charges.split(";");
        getcompdetails.Basic_charges = 0;
        getcompdetails.Admin_charges = 0;
        getcompdetails.Guest_charges = 0;
       //loop base charge array
        for (let i = 0; i < baseChargeArray.length; i++) {
            const charge = baseChargeArray[i];
            if (charge.includes("Base_charges:")){
                getcompdetails.Basic_charges = charge.split(":")[1];
            }
            else if (charge.includes("Admin_charges:")){
                getcompdetails.Admin_charges = charge.split(":")[1];
            }
            else if (charge.includes("Guest_charges:")) {
                getcompdetails.Guest_charges = charge.split(":")[1];
            }
        }

    }
  setcompDetails(getcompdetails);
  setIsLoading(false);
}
  const redirectRegister = () => {
    window.location.href = `/competition/register/${id}`;
  };

const MobileCompInfo = () =>{
  return(

      <table style={{width: "70%"}}>
          <tr>
              <td className="InfoHeaderMobile"><b>WCA Website</b></td>

          </tr>
          <tr>
              <td className="InfoContentMobile"><a href={"https://www." + compDetails.wcaURL}>{compDetails.wcaURL}</a>
              </td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Venue</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{compDetails.Venue}</td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Events</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">
                  <div><EventIconComponent events={compDetails.Events}/></div>
              </td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Competition Date (GMT+8)</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{compDetails.Competition_date}</td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Registration Open Date(Your Time Zone)</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{compDetails.registrationOpenTime ? moment.unix(compDetails.registrationOpenTime).format("DD-MM-YYYY h:mm:ss A") : "No Date"}</td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Registration Close Date(Your Time Zone)</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{compDetails.registrationCloseTime ? moment.unix(compDetails.registrationCloseTime).format("DD-MM-YYYY h:mm:ss A") : "No Date"}</td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile">
                  <b>Address</b>
              </td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{compDetails.Address}</td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Address Details</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{compDetails.address_details}</td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Organizer Email</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{compDetails.contact_email}</td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Organizer</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{compDetails.Organizers}</td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>WCA Delegate</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{compDetails.wca_delegates}</td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Participant Refund Policy</b></td>
          </tr>
          <tr>
              {(id !== "SlowandEasySelangor2024"&&id !== "FMC2024" && id !== "MalaysiaNewComer2025" ) && <td className="InfoContentMobile"><b>50% refund (Excluded Admin Fee) for withdrawal before
                  registration closing
                  date, no refund after registration closing date.</b></td>}
              {(id === "SlowandEasySelangor2024"||id === "FMC2024" || id === "MalaysiaNewComer2025" ) && <td className="InfoContentMobile"><b>No Refund of withdrawal for this competition after getting accepted for registration.</b></td>}
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Waiting List Refund Policy</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">Full refund with 4% transaction fee deducted for all withdrawal from
                  waiting list.
              </td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Base Fee (RM)</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{(compDetails.Basic_charges !== null && compDetails.Basic_charges !== undefined) ? `RM ${compDetails.Basic_charges}` : 0}</td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Admin Fee (RM)</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{(compDetails.Admin_charges !== null && compDetails.Admin_charges !== undefined) ? `RM ${compDetails.Admin_charges}` : 0}</td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Per Guest Fee (RM)</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{(compDetails.Guest_charges !== null && compDetails.Guest_charges !== undefined) ? `RM ${compDetails.Guest_charges}` : 0}</td>
          </tr>
          <tr>
              <td className="InfoHeader"><b>Event Price</b></td>
          </tr>
          <tr>
              <td className="InfoContent"><EventPriceList events={compDetails.Events_price}/></td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Participant Limit</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{compDetails.Participant_limit}</td>
          </tr>
          <tr>
              <td className="InfoHeaderMobile"><b>Current Approved Participant Count</b></td>
          </tr>
          <tr>
              <td className="InfoContentMobile">{`${compDetails.current_participant_count !== undefined ? compDetails.current_participant_count : ""}`}</td>
          </tr>

      </table>
  )
}
    const WebCompInfo = () => {
        return (
            <table style={{width: "80%"}}>
                <tr>
                    <td className="InfoHeader"><b>WCA Website</b></td>
                    <td className="InfoContent"><a href={"https://www." + compDetails.wcaURL}>{compDetails.wcaURL}</a>
                    </td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Venue</b></td>
                    <td className="InfoContent">{compDetails.Venue}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Events</b></td>
                    <td className="InfoContent"><EventIconComponent events={compDetails.Events}/></td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Competition Date (GMT+8)</b></td>
                    <td className="InfoContent">{compDetails.Competition_date}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Registration Open Date (Your Time Zone)</b></td>
                    <td className="InfoContent">{compDetails.registrationOpenTime ? moment.unix(compDetails.registrationOpenTime).format("DD-MM-YYYY h:mm:ss A") : "No Date"}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Registration Close Date (Your Time Zone)</b></td>
                    <td className="InfoContent">{compDetails.registrationCloseTime ? moment.unix(compDetails.registrationCloseTime).format("DD-MM-YYYY h:mm:ss A") : "No Date"}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Address</b></td>
                    <td className="InfoContent">{compDetails.Address}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Address Details</b></td>
                    <td className="InfoContent">{compDetails.address_details}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Organizer Email</b></td>
                    <td className="InfoContent">{compDetails.contact_email}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Organizer</b></td>
                    <td className="InfoContent">{compDetails.Organizers}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>WCA Delegate</b></td>
                    <td className="InfoContent">{compDetails.wca_delegates}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Participant Refund Policy</b></td>
                    {(id === "SlowandEasySelangor2024"||id === "FMC2024"|| id === "MalaysiaNewComer2025"  )&& <td className="InfoContent"><b>No Refund of withdrawal for this competiton</b></td>}
                    {(id !== "SlowandEasySelangor2024" &&id !== "FMC2024"&& id !== "MalaysiaNewComer2025" )&&   <td className="InfoContent"><b>50% refund (Excluded Admin Fee) for withdrawal before
                        registration closing
                        date, no refund after registration closing date.</b></td>}
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Waiting List Refund Policy</b></td>
                    <td className="InfoContent">Full refund with 4% transaction fee deducted for all withdrawal from
                        waiting list.
                    </td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Base Fee (RM)</b></td>
                    <td className="InfoContent">{(compDetails.Basic_charges !== null && compDetails.Basic_charges !== undefined) ? `RM ${compDetails.Basic_charges}` : 0}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Event Price</b></td>
                    <td className="InfoContent"><EventPriceList events={compDetails.Events_price}/></td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Admin Fee (RM)</b></td>
                    <td className="InfoContent">{(compDetails.Admin_charges !== null && compDetails.Admin_charges !== undefined) ? `RM ${compDetails.Admin_charges}` : 0}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Per Guest Fee (RM)</b></td>
                    <td className="InfoContent">{(compDetails.Guest_charges !== null && compDetails.Guest_charges !== undefined) ? `RM ${compDetails.Guest_charges}` : 0}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Participant Limit</b></td>
                    <td className="InfoContent">{compDetails.Participant_limit}</td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Current Approved Participant Count</b></td>
                    <td className="InfoContent">{`${compDetails.current_participant_count !== undefined ? compDetails.current_participant_count : ""}`}</td>
                </tr>

            </table>
        )
    }
    return (
        <div style={{display: "flex"}}>


            <div className="infoText">
                <h1>
                    {compDetails.Competition_name}
                </h1>
                <CompetitionMenuItem competition={id} isAC2024Path={isAC2024Path}/>
                <br/>
                {isMobile ? <MobileCompInfo/> : <WebCompInfo/>}

                <p>


                    <br/>
                    <b>WCA Regulations:</b>
                    <br/>
                    This competition is recognized as an official World Cube Association competition. Therefore, all
                    competitors must be familiar with the WCA regulations.
                </p>
                {compDetails.registerOpen === "true" && compDetails.participant_limit_reached === false &&
                    <Button style={{marginTop: "5%", fontSize: 20, marginBottom: "10px"}}
                            onClick={() => redirectRegister()} variant="contained">Register Now</Button>}
            </div>
            <LoadingOverlay isLoading={isLoading}/>
        </div>

    );
}

export default CompInfoPage;