import {Elements} from '@stripe/react-stripe-js';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import { useState,useEffect } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import Button from '@mui/material/Button';
import { maxHeight } from '@mui/system';

const PaymentComponent = () => {
  const { paymentKey } = useParams();
  //check if there is a countdown time in local storage
  const savedTime = localStorage.getItem(`countDown_${paymentKey}`);

  const [countdown, setCountdown] = useState(savedTime || 1000);
    const PUBLISHABLE_KEY =process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const [stripePromise, setStripePromise] = useState(() => loadStripe(PUBLISHABLE_KEY));
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ''; // This line is necessary for Chrome
      const message = 'Are you sure you want to leave this page?';
      e.returnValue = message;
      return message;
    };

  //  window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
   //   window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    if (countdown === 0 || savedTime === "0") {
//redirect to timeoutpage
      window.location.href = `/paymentTimeout`;
      
    }
  }, [countdown]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
        //save countdown time in local storage
        localStorage.setItem(`countDown_${paymentKey}`, countdown-1);


      }
    }, 1500);
    if (countdown === 0 || savedTime === 0) {
      //redirect to timeoutpage
      window.location.href = `/paymentTimeout`;

    }

    return () => {
      clearTimeout(timer);
    };
  }, [countdown]);

    const options = {
        // passing the client secret obtained in step 3
        clientSecret: paymentKey,
        // Fully customizable with appearance API.
        appearance: {/*...*/},
      };
    return (
      <div style={{ display: 'flex',flexDirection:"column",maxWidth:"500px",marginLeft:"auto",marginRight:"auto",marginTop:"30px" }}>
        <div>
 <Elements stripe={stripePromise} options={options} >
 < PaymentElement/>
        <StripePaymentPanel/>
   
          </Elements>
        </div>
        <div>    <p style={{color:"red"}}>Remaining payment time: {toDaysMinutesSeconds(countdown)}</p>
        </div>    
        </div>
    );
    }

    const StripePaymentPanel = () => {
        const elements = useElements();
        const stripe = useStripe();

        const onStripeSubmitPayment =async (event) =>{

            event.preventDefault();
    
        if (!stripe || !elements || stripe == undefined) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
    
        const {error} = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
           // return_url: 'http://localhost:3000/competition/Payment/Result',
           return_url: 'https://www.mycubeassociation.org/competition/Payment/Result',
          },
        });
    
    
        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
            }
   
      return (<div style={{ display: 'flex', height: '100%' }}>

        <Button variant="contained" style={{ marginTop: "5%", marginBottom: "10%", fontSize: 25 ,maxHeight:"10%" }} onClick={onStripeSubmitPayment} disabled={!stripe}>Submit</Button>

          </div>)
      
}
    
function toDaysMinutesSeconds(totalSeconds) {
  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const days = Math.floor(totalSeconds / (3600 * 24));

  const secondsStr = makeHumanReadable(seconds, 'second');
  const minutesStr = makeHumanReadable(minutes, 'minute');
  const hoursStr = makeHumanReadable(hours, 'hour');
  const daysStr = makeHumanReadable(days, 'day');

  return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(/,\s*$/, '');
}

function makeHumanReadable(num, singular) {
  return num > 0
    ? num + (num === 1 ? ` ${singular}, ` : ` ${singular}s, `)
    : '';
}
export default PaymentComponent;